import logo from '../images/logo.svg';
import '../App.css';

function E404() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>
          RDSoft Rafał Derlatka
        </h1>
        <h2>
          404 :(
        </h2>
        <p>
          The page you are looking for does not exist.
        </p>
      </header>
    </div>
  );
}

export default E404;
